import { axios } from '../axios';
import {
  ChangeOrganizationMemberPrivilegesRequest,
  InvoiceResponse,
  OrganizationInviteRequest,
  OrganizationInviteResponse,
  OrganizationMemberResponse,
  OrganizationPublicResponse,
  UpdateOrganizationSettingsRequest,
} from '../api.types';

export const getOrganizationMembers = (): Promise<
  OrganizationMemberResponse[]
> => {
  return axios.get('/organizations/members').then((resp) => resp.data);
};

export const getPendingOrganizationInvites = (): Promise<
  OrganizationInviteResponse[]
> => {
  return axios.get('/organizations/pending-invites').then((resp) => resp.data);
};

export const postInviteToOrganization = (
  body: OrganizationInviteRequest,
): Promise<void> => {
  return axios.post('/organizations/invite', body);
};

export function getOrganizationByInviteId(
  inviteId: string,
): Promise<OrganizationPublicResponse> {
  return axios
    .get<OrganizationPublicResponse>(`/organizations/invite/${inviteId}`)
    .then((resp) => resp.data);
}

export function deleteOrganizationInvite(inviteId: string): Promise<void> {
  return axios.delete(`/organizations/invite/${inviteId}`);
}

export function putPrivilegesForMember(data: {
  body: ChangeOrganizationMemberPrivilegesRequest;
  memberId: string;
}): Promise<void> {
  return axios.put(
    `/organizations/members/${data.memberId}/privileges`,
    data.body,
  );
}

export function putActivateMember(data: { memberId: string }): Promise<void> {
  return axios.post(`/organizations/members/${data.memberId}/activate`);
}

export function putDeactivateMember(data: { memberId: string }): Promise<void> {
  return axios.post(`/organizations/members/${data.memberId}/deactivate`);
}

export function getInvoices(): Promise<InvoiceResponse[]> {
  return axios.get('/organizations/invoices').then((resp) => resp.data);
}

export function getAndOpenInvoiceFile({
  invoiceId,
}: {
  invoiceId: string;
}): Promise<void> {
  return axios
    .get(`/organizations/invoices/${invoiceId}/file`, {
      responseType: 'blob',
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('target', '_blank'); // open in new tab
      link.setAttribute('download', 'file.pdf'); // optional, if you want to download instead
      document.body.appendChild(link);
      link.click();
    });
}

export const putUploadOrganizationLogo = (body: { file: File }) => {
  const formData = new FormData();
  formData.append('logo', body.file);
  return axios.put<void>('/organizations/logo', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const deleteOrganizationLogo = () => {
  return axios.delete<void>('/organizations/logo');
};

export const putUpdateOrganizationSettings = (
  body: UpdateOrganizationSettingsRequest,
) => {
  return axios.put<void>('/organizations/settings', body);
};

export const checkIfValueForExternalIdNameIsTaken = (value: string) => {
  return axios.get<{ taken: boolean }>(
    `/organizations/external_id_name/taken/${value}`,
  );
};

export enum WatermarkPositionEnum {
  TOP_LEFT = 'TOP_LEFT',
  TOP_RIGHT = 'TOP_RIGHT',
  BOTTOM_LEFT = 'BOTTOM_LEFT',
  BOTTOM_RIGHT = 'BOTTOM_RIGHT',
  CENTER_LEFT = 'CENTER_LEFT',
  CENTER_RIGHT = 'CENTER_RIGHT',
  CENTER = 'CENTER',
  TOP = 'TOP',
  BOTTOM = 'BOTTOM',
}

// Interface for Watermark Response
export interface WatermarkResponse {
  watermark_id: string; // UUID as a string
  watermark_position: WatermarkPositionEnum;
  watermark_url: string;
  watermark_opacity: number;
}

export const postUploadOrganizationWatermark = (body: {
  file: File;
  watermarkPosition: WatermarkPositionEnum;
  watermarkOpacity: number;
}) => {
  const formData = new FormData();
  formData.append('watermark', body.file);
  return axios.post<void>('/organizations/watermark', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    params: {
      watermark_position: body.watermarkPosition,
      watermark_opacity: body.watermarkOpacity,
    },
  });
};

export const getOrganizationWatermark = (): Promise<WatermarkResponse> => {
  return axios.get('/organizations/watermark').then((resp) => resp.data);
};

export const deleteOrganizationWatermark = () => {
  return axios.delete<void>('/organizations/watermark');
};
