import React from 'react';
import { classNames } from '../../style/class-names';
import { ActivityFeedLoaderItem } from './activity-feed-loader-item';

interface Props {
  length?: number;
  className?: string;
}

const ActivityFeedLoader = ({ length = 4, className = '' }: Props) => {
  return (
    <ul role="list" className={`space-y-8 ${className}`}>
      {Array.from({ length }).map((event, activityItemIdx) => (
        <li className="relative flex gap-x-4" key={activityItemIdx}>
          <div
            className={classNames(
              activityItemIdx === length - 1 ? 'h-6' : '-bottom-8',
              'absolute left-0 top-0 flex w-8 animate-pulse justify-center',
            )}
          >
            <div className="w-px bg-gray-200" />
          </div>
          <ActivityFeedLoaderItem />
        </li>
      ))}
    </ul>
  );
};

export default ActivityFeedLoader;
