import React from 'react';
import InputLabel, { InputLabelProps } from '../shared/input-label';
import WysiwigTextInput, { WysiwigTextInputProps } from './wysiwig-text-input';

interface WysiwigTextInputLabeledProps
  extends WysiwigTextInputProps,
    Omit<InputLabelProps, 'labelFor'> {
  className?: string;
  id: string;
  isError?: boolean;
  errorMessage?: string;
}

const WysiwigTextInputLabeled = ({
  label,
  labelClassName,
  id,
  isRequired,
  ...rest
}: WysiwigTextInputLabeledProps) => {
  return (
    <>
      <InputLabel
        labelFor={id}
        label={label}
        labelClassName={labelClassName}
        isRequired={isRequired}
      />
      <WysiwigTextInput {...rest} />
    </>
  );
};

export default WysiwigTextInputLabeled;
